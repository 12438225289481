:root {
  /* colors */
  --primary-color: #53a33a;
  --secondary-color: #2a91f0;
  --tertiary-color: #bf4380;
  --quaternary-color: #ef970d;

  /* bg variants */
  --primary-bg-color: #000000;
  --secondary-bg-color: #dff240;
  --tertiary-bg-color: #676767;
  --quaternary-bg-color: #ffffff;

  /* header footer bg */
  --header-bg-color: var(--primary-bg-color);
  --footer-bg-color: var(--primary-bg-color);
  --navigation-bg-color: var(--primary-bg-color);

  /* fonts */
  --primary-font: "Open Sans", sans-serif;
  --secondary-font: "Montserrat", sans-serif;
  --tertiary-font: "Lato", sans-serif;

  --font-size: 15px;

  /* fonts colors */
  --primary-font-color: #e6e6e6;
  --secondary-font-color: #cfcfcf;
  --tertiary-font-color: #b7b7b7;
  --quaternary-font-color: #000000;

  /* viewport */
  --viewport-max-width: 100%;
  --header-height: 70px;

  /* spacing */
  --spacing-unit-xsm: 5px;
  --spacing-unit-sm: 20px;
  --spacing-unit-md: 40px;
  --spacing-unit-lg: 60px;
  --spacing-unit-xlg: 120px;
  --spacing-unit-xxlg: 200px;

  /* icons */
  --icons-lg: 2rem;
  --icons-md: 1.5rem;
  --icons-sm: 1rem;
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  :root {
    --font-size: 14px;
  }
}
