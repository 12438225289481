/* App style */
.App {
  position: relative;
  width: 100%;
  height: 100%;
}

/* section style */
.section {
  position: relative;
  width: 100%;
  height: 100%;
  float: left;
  clear: both;
  overflow: hidden;
}

/* viewport style */
.viewport {
  position: relative;
  margin: 0 auto;
  height: 100%;
  clear: both;
  overflow: hidden;
  width: calc(100% - 80px);
  max-width: var(--viewport-max-width);
}

/* dim bg */
.dim-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  pointer-events: none;
}

[data-navigation="opened"] .dim-bg {
  background-color: rgba(0, 0, 0, 0.4);
  pointer-events: painted;
}
/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  /* viewport style */
  .viewport {
    width: calc(100% - 30px);
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}
