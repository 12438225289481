/*Document */
html,
body,
#root {
  padding: 0;
  margin: 0;
  /*  */
  width: 100%;
  height: 100%;
  /*  */
  font-family: var(--primary-font);
  font-size: var(--font-size);
  font-weight: 600;
  color: var(--primary-font-color);
  line-height: 1.4;
  /*  */
  direction: ltr;
  scroll-behavior: auto;
  background-color: var(--primary-bg-color);
  /*  */
  user-select: none;

  /**/
  -webkit-transition: background-color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: background-color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: background-color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: background-color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: background-color 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.scroll-disabled {
  overflow: hidden;
}

.unsupported-screens {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  pointer-events: none;
  background-color: white;
  display: none;
}
/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}

/* undesired size */
@media only screen and (max-width: 310px) {
  .unsupported-screens {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .unsupported-screens img {
    max-width: 150px;
  }
}
