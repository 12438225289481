.transition-fast {
  /**/
  -webkit-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}

.transition-medium {
  /**/
  -webkit-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
  transition: all 1.5s cubic-bezier(0.75, 0, 0.125, 1);
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}

@-moz-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}

@-ms-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 1%, 0) rotateZ(-360deg);
  }
}
