.modal-overlay {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*  */
@supports (backdrop-filter: blur(15px)) or (-webkit-backdrop-filter: blur(15px)) {
  /* NOW do stuff that requires filters to work */
  .modal-overlay {
    /*  */
    background-color: transparent;
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
  }
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 90%;
}

.modal-close-btn {
  position: fixed;
  top: 10px;
  right: 35px;
  cursor: pointer;
  /*  background-color: white; */
}
.modal-close-btn svg {
  width: 30px;
  height: 30px;
  /*  color: black; */
}

/* icon button */
.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px 10px;
  border-radius: 10px;
}
.icon-button .icon {
  width: 52px;
  height: 52px;
  color: white;

  /**/
  -webkit-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -moz-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -ms-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  -o-transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
  transition: all 0.4s cubic-bezier(0.75, 0, 0.125, 1);
}
.icon-button span {
  font-size: 1rem;
  font-weight: 600;
  margin-top: 10px;
  color: var(--tertiary-font-color);
}
.icon-button:hover .icon {
  color: var(--quaternary-color);
}

/*-------------Responsive------------------*/
@media only screen and (max-width: 1400px) {
}

@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .icon-button span {
    display: none;
  }
  .icon-button .icon {
    width: 30px;
    height: 30px;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 410px) {
}
@media only screen and (max-width: 320px) {
}

/* Custom, iPhone Retina */
@media only screen and (max-width: 550px) {
}
